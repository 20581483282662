export function isUrlExists(imageObj: Queries.Seo_ComponentBasicAllResolutionsImage|null): boolean {
  if (!imageObj) return false;
  const values = Object.values(imageObj);

  return values.every((item) => {
    if (typeof item === "object" && item !== null) {
      return item.data?.attributes?.url !== undefined;
    }
    return false;
  });
}

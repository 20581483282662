export const isCatalogBannerActive = (
  isShown: boolean | null,
  isUserLoggedIn: boolean,
  onlyForAuthorized: boolean | null,
  showOnProd: boolean | null,
) => {
  if (process.env.ENV === "prod" && !showOnProd) return null;
  const isExpertListingBannerActive = process.env.GATSBY_IS_EXPERT_LISTING_BANNER_ACTIVE === "true" && isShown;
  return onlyForAuthorized ? isUserLoggedIn && isExpertListingBannerActive : isExpertListingBannerActive;
};

export const isWideBannerActive = (
  isShown: boolean | null,
  isUserLoggedIn: boolean,
  onlyForAuthorized: boolean | null,
  showOnProd: boolean | null,
) => {
  if (process.env.ENV === "prod" && !showOnProd) return null;

  const isExpertListingBannerActive = process.env.GATSBY_IS_WIDE_BANNER_ACTIVE === "true" && isShown;

  return onlyForAuthorized ? isUserLoggedIn && isExpertListingBannerActive : isExpertListingBannerActive;
};

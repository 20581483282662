import React, { FC } from "react";

import { graphql } from "gatsby";

import ProfileLayout from "@/components/profile/profileLayout";
import ReferralLink from "@/components/profile/referralLink";
import { isBrowser } from "@/utils/env";

interface ReferralPageProps {
  data: {
    seo: {
      wideBanner: Queries.Seo_WideBannerEntityResponse
    }
  }
}

const ReferralLinkPage:FC<ReferralPageProps> = ({ data }) => {
  if (!isBrowser()) {
    return null;
  }

  const wideBannerData = data.seo.wideBanner.data?.attributes;

  return (
    <ProfileLayout wideBannerData={wideBannerData}>
      <ReferralLink />
    </ProfileLayout>
  );
};

export const GET_ACCOUNT_PAGE_PROPS = graphql`
query MyQuery {
  seo {
    wideBanner {
      data {
        attributes {
          wideBannerIsShownOnProfile
          wideBannerHeight
          wideBannerDesktopImage {
            oneX {
              data {
                attributes {
                  url
                }
              }
            }
            threeX {
              data {
                attributes {
                  url
                }
              }
            }
            twoX {
              data {
                attributes {
                  url
                }
              }
            }
          }
          wideBannerMetric {
            metricId
            metricUserIdFlag
            metricInfo
          }
          wideBannerMobileImage {
            oneX {
              data {
                attributes {
                  url
                }
              }
            }
            threeX {
              data {
                attributes {
                  url
                }
              }
            }
            twoX {
              data {
                attributes {
                  url
                }
              }
            }
          }
          wideBannerTabletImage {
            oneX {
              data {
                attributes {
                  url
                }
              }
            }
            threeX {
              data {
                attributes {
                  url
                }
              }
            }
            twoX {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default ReferralLinkPage;
